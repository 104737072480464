import { render, staticRenderFns } from "./Recipe.vue?vue&type=template&id=0856f148&scoped=true"
import script from "./Recipe.vue?vue&type=script&lang=js"
export * from "./Recipe.vue?vue&type=script&lang=js"
import style0 from "./Recipe.vue?vue&type=style&index=0&id=0856f148&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0856f148",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/node-apps/im.ingenes.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0856f148')) {
      api.createRecord('0856f148', component.options)
    } else {
      api.reload('0856f148', component.options)
    }
    module.hot.accept("./Recipe.vue?vue&type=template&id=0856f148&scoped=true", function () {
      api.rerender('0856f148', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Recipe.vue"
export default component.exports